import axios from 'axios'
import {API_REQUEST, apiError, apiSuccess} from '../../actions/ApiActions'

export const apiMiddleware =
  ({dispatch}) =>
  (next) =>
  (action) => {
    next(action)

    if (action.type.includes(API_REQUEST)) {
      const body = JSON.stringify(action.payload)
        console.log("body = " + body)
      const {url, method, headers, feature, uiName, responseType} = action.meta
        console.log("url = " + action.meta.url)
        console.log("method = " + action.meta.method)
        console.log("headers = " + JSON.stringify(action.meta.headers))
        console.log("feature = " + action.meta.feature)
        console.log("uiName = " + action.meta.uiName)
        console.log("responseType = " + action.meta.responseType)

      const request = () =>
        method === 'GET' ? {method, url, headers, responseType} : {method, url, headers, data: body}
      axios(request())
        .then((response) => {
          dispatch(apiSuccess({response, feature, uiName}))
        })
        .catch((error) => {
          dispatch(apiError({error, feature, uiName}))
        })
    }
  }
