import amplitude from 'amplitude-js'
import {store} from '../../../redux/Store'
import {AMPLITUDE_SCREEN_NAME, AMPLITUDE_SCREENNAME_MAP, AMPLITUDE_EVENT_NAME} from './AnalyticsConstants'
import {utils} from '../Utils'

class Analytics {
  init(apiKey, userGuid) {
    amplitude.getInstance().init(apiKey)
    amplitude.getInstance().setUserId(userGuid)
  }

  getAmplitudeScreenName() {
    return AMPLITUDE_SCREENNAME_MAP.get(store.getState().activePage)
  }

  fireEvent(eventName, properties) {
    amplitude.getInstance().logEvent(eventName, properties)
  }

  fireLinkTappedEvent(screenName, linkName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.LINK_TAPPED, {screenName, linkName})
  }

  fireSetPreferencesTappedEvent(screenName, sectionName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.SET_PREFERENCES_TAPPED, {screenName, sectionName})
  }

  fireMobileAppCookiesTappedEvent(screenName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.MOBILE_APP_COOKIES_TAPPED, {screenName})
  }

  fireVehicleSelectedEvent(screenName, vehicleName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.PRIVACY_VEHICLE_SELECTED, {screenName, vehicleName})
  }

  fireToggleTappedEvent(screenName, featureName, toggleStatus) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.TOGGLE_TAPPED, {screenName, featureName, toggleStatus})
  }

  fireToggleConfirmationTappedEvent(screenName, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.TOGGLE_CONFIRMATION_TAPPED, {
      screenName,
      featureName,
    })
  }

  fireCancelTappedEvent(screenName, previousScreen, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.CANCEL_TAPPED, {
      screenName,
      previousScreen,
      featureName,
    })
  }

  firePageExitTappedEvent(screenName, previousScreen, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.PAGE_EXIT_TAPPED, {
      screenName,
      previousScreen,
      featureName,
    })
  }

  fireLearnMoreTappedEvent(screenName, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.PRIVACY_LEARN_MORE_TAPPED, {screenName, featureName})
  }

  fireLearnMoreAccordionTappedEvent(screenName, featureName, accordionName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.LEARN_MORE_ACCORDION_TAPPED, {screenName, featureName, accordionName})
  }

  fireGoBackTappedEvent(screenName, previousScreen, runningBlockName, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.GO_BACK_TAPPED, {
      screenName,
      previousScreen,
      runningBlockName,
      featureName,
    })
  }

  fireExpandTappedEvent(screenName, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.EXPAND_TAPPED, {screenName, featureName})
  }

  fireCollapseTappedEvent(screenName, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.COLLAPSE_TAPPED, {screenName, featureName})
  }

  fireUnlinkThirdPartiesTappedEvent(screenName, featureName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.UNLINK_THIRD_PARTIES_TAPPED, {screenName, featureName})
  }

  fireUnlinkAccountTappedEvent(screenName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.UNLINK_ACCOUNT_TAPPED, {screenName})
  }

  fireUnlinkTappedEvent(screenName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.PRIVACY_UNLINK_TAPPED, {screenName})
  }

  fireUnlinkCompletedEvent(screenName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.PRIVACY_UNLINK_COMPLETED, {screenName})
  }

  firePageViewEvent(screenName, features) {
    const properties = {screenName}
    features.forEach((feature) => {
      const propertyName = `${utils.toCamelCase(feature.uiName)}DefaultValue`
      if (feature.statusCode) {
        properties[propertyName] = feature.statusCode === 'Y' ? 'On' : 'Off'
      } else if (feature.consentStatus) {
        properties[propertyName] = feature.consentStatus === '1' ? 'On' : 'Off'
      }
    })
    this.fireEvent(AMPLITUDE_EVENT_NAME.PRIVACY_PAGE_VIEWED, properties)
  }

  fireGooglePrivacyPolicyTapped(screenName) {
    this.fireEvent(AMPLITUDE_EVENT_NAME.GOOGLE_PRIVACY_POLICY_TAPPED, {screenName})
  }

  getUnlinkAccountScreenName(uiName) {
    return `${AMPLITUDE_SCREEN_NAME.UNLINK_ACCOUNT} ${uiName}`
  }

  getUnlinkPopupScreenName(uiName) {
    return `${AMPLITUDE_SCREEN_NAME.UNLINK} ${uiName}`
  }
}

export const analytics = new Analytics()
